import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSettings as useSettingsQuery } from 'services/settings';

const SettingsContext = React.createContext(null);

export const SettingsContextProvider = ({ children }) => {
  const { data = {}, isLoading } = useSettingsQuery();

  return (
    <SettingsContext.Provider
      value={{
        settings: data
      }}
    >
      {!isLoading ? children : <Spinner animation="grow" variant="info" size="lg" />}
    </SettingsContext.Provider>
  );
};

export function useSettings() {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error('useSettings must be used within a SettingsContextProvider');
  }

  return context;
}

import React from 'react';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useUserSelector } from 'services/UserSelectorContext';

SignOutButton.propTypes = {};

function SignOutButton() {
  const { clearJwtTokens } = useUserSelector();

  const onLogout = async () => {
    try {
      clearJwtTokens();
      window.location.href = '/';
    } catch (error) {
      console.error(error);
      alert('Error signing out');
    }
  };

  return (
    <FontAwesomeIcon
      icon={faRightFromBracket}
      onClick={onLogout}
      className="text-white"
      role="button"
    />
  );
}

export default SignOutButton;

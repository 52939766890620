import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loadable from 'components/Loadable';
import { ProtectedRoute } from 'components/ProtectedRoute';
import Logout from 'components/SignOutButton';

const Home = Loadable(lazy(() => import('./pages/home/Home')));
const Users = Loadable(lazy(() => import('./pages/users/Users')));
const Guests = Loadable(lazy(() => import('./pages/guests/Guests')));
const ReferralLogs = Loadable(lazy(() => import('./pages/referrals/ReferralLogs')));
const ReferralEarnings = Loadable(lazy(() => import('./pages/referrals/ReferralEarnings')));
const ReferralEarningsReviewRequests = Loadable(
  lazy(() => import('./pages/referrals/ReferralEarningsReviewRequests'))
);
const Tenants = Loadable(lazy(() => import('./pages/references/Tenants')));
const PassportRequests = Loadable(lazy(() => import('./pages/passport/PassportRequests')));
const ReferralEarningsWithdrawals = Loadable(
  lazy(() => import('./pages/referrals/ReferralEarningsWithdrawals'))
);
const Login = Loadable(lazy(() => import('./pages/account/Login')));
const PasswordRecover = Loadable(lazy(() => import('./pages/account/PasswordRecover')));
const PasswordRecoverConfirm = Loadable(
  lazy(() => import('./pages/account/PasswordRecoverConfirm'))
);
const NotFound = Loadable(lazy(() => import('./pages/common/NotFound')));

const RoutesComponent = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/guests"
        element={
          <ProtectedRoute>
            <Guests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/logs"
        element={
          <ProtectedRoute>
            <ReferralLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/earnings"
        element={
          <ProtectedRoute>
            <ReferralEarnings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/earnings/review-requests"
        element={
          <ProtectedRoute>
            <ReferralEarningsReviewRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/earnings/withdrawals"
        element={
          <ProtectedRoute>
            <ReferralEarningsWithdrawals />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reference-checks"
        element={
          <ProtectedRoute>
            <Tenants />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport-requests"
        element={
          <ProtectedRoute>
            <PassportRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport-requests/:email"
        element={
          <ProtectedRoute>
            <PassportRequests />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:email" element={<Login />} />
      <Route path="/password/recover" element={<PasswordRecover />} />
      <Route path="/password/recover/confirm/:email" element={<PasswordRecoverConfirm />} />
      <Route
        path="/logout"
        element={
          <ProtectedRoute>
            <Logout />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RoutesComponent;

import { Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useUserSelector } from 'services/UserSelectorContext';

import { SettingsContextProvider } from 'hooks/useSettings';

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, isLoading } = useUserSelector();

  if (!isLoggedIn && !isLoading) {
    return <Navigate to="/login" replace />;
  } else if (isLoading) {
    return <Spinner animation="grow" variant="info" size="lg" />;
  }

  return <SettingsContextProvider>{children}</SettingsContextProvider>;
};

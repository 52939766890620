import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export const useSettings = () => {
  return useQuery({
    queryKey: ['settings'],
    queryFn: async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings`);

      return data;
    }
  });
};

import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const LoaderWrapper = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  marginTop: 16
}));

const Loader = () => (
  <LoaderWrapper>
    <Spinner animation="grow" size="lg" />
  </LoaderWrapper>
);

export default Loader;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { UserSelectorContextProvider } from './services/UserSelectorContext';
import App from './App';
import GlobalStyles from './global-styles';
import reportWebVitals from './reportWebVitals';

import './index.css';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { theme } from './theme';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCar,
  faHome,
  faHandHoldingUsd,
  faCreditCard,
  faLandmark,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';

import './i18n/config';

library.add(faCar, faHome, faHandHoldingUsd, faCreditCard, faLandmark, faUserCircle);

const root = ReactDOM.createRoot(document.getElementById('root'));

// !Due to the webpack5 migration and the polyfills required to have process.env
// !use REACT_APP_ENV instead on NODE_ENV

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <BrowserRouter>
      <UserSelectorContextProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </UserSelectorContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const colors = Object.freeze({
  white: '#fff',
  black: '#191919',

  gray10: '#F5F7FA',
  gray15: '#E6EBF2',
  gray20: '#B1BDCB',
  gray30: '#8495AA',
  gray45: '#626984',
  gray70: '#464E6C',
  gray140: '#092349',

  blue10: '#f1f4fd',
  blue15: '#DEE7FF',
  blue20: '#A6BCF7',
  blue30: '#5F85ED',
  blue45: '#4569CC',
  blue70: '#294BA9',
  blue140: '#091E57',

  magenta10: '#FFEDF3',
  magenta15: '#FFD9E5',
  magenta20: '#FF99BB',
  magenta30: '#FF397F',
  magenta45: '#D90170',
  magenta70: '#A4005A',
  magenta140: '#4B0031',

  orange10: '#FDF1E6',
  orange15: '#FFDFBF',
  orange20: '#FFA959',
  orange30: '#DE6B00',
  orange45: '#BD4B00',
  orange70: '#913100',
  orange140: '#421600',

  red10: '#FEEDEC',
  red15: '#FFD7D5',
  red20: '#FF9C96',
  red30: '#F34C4E',
  red45: '#D31D1F',
  red70: '#A70000',
  red140: '#4D0000',

  green10: '#E1FAE1',
  green15: '#CFF8CF',
  green20: '#7AE87A',
  green30: '#429D51',
  green45: '#00802A',
  green70: '#005E00',
  green140: '#002A00',

  citrus10: '#F5F3DC',
  citrus15: '#EAE7C6',
  citrus20: '#D4C62B',
  citrus30: '#919100',
  citrus45: '#697300',
  citrus70: '#465400',
  citrus140: '#202600',

  teal10: '#E1FAFA',
  teal15: '#CEF2F1',
  teal20: '#6DDEDA',
  teal30: '#009E91',
  teal45: '#007D74',
  teal70: '#005C54',
  teal140: '#002B28',

  purple10: '#F4F0FF',
  purple15: '#EAE0FF',
  purple20: '#C6ABFF',
  purple30: '#9B6BFE',
  purple45: '#804AEF',
  purple70: '#5E2DC4',
  purple140: '#24006F'
});

export const theme = Object.freeze({
  colors: {
    ...colors,
    primaryLight: colors.white,
    primaryDark: colors.gray140,

    interactionLight: colors.blue10,
    interaction: colors.blue45,
    interactionDark: colors.blue70,

    buttonPrimary: colors.magenta45,
    buttonPrimaryDark: colors.magenta70,
    buttonSecondaryLight: colors.gray10,

    default: colors.white
  },
  gutter: {
    xs: '.4rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem'
  },
  font: {
    size: {
      xs: '0.8125rem', // 13px
      sm: '0.875rem', // 14px
      md: '1rem', // 16 px / title small /
      xl: '1.25rem' // 20px / title medium /
    },
    weight: {
      light: 400,
      normal: 500,
      bold: 700
    }
  }
});

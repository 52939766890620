import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Routes from './Routes';
import SideBar from 'components/Menu';

const AppContainer = styled(Container)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: auto;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContainer fluid id="scrollable">
        <Row>
          <SideBar />
          <Container className="text-center mt-2 mb-2 min-h-100">
            <Routes />
          </Container>
        </Row>
      </AppContainer>
    </QueryClientProvider>
  );
}

export default App;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages } from './languages/languages';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = Object.entries(languages).reduce(
  (acc, [lang, translation]) => ({ ...acc, [lang]: { translation } }),
  {}
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      lookupLocalStorage: 'language'
    },
    debug: true,
    interpolation: {
      escapeValue: false
    },
    saveMissing: true,
    missingKeyHandler: (ng, ns, key, fallbackValue) => {
      console.error(`Key not found ${key}, ${ng}, ${ns}, ${fallbackValue}`);
    },
    fallbackLng: ['en', 'es', 'pt', 'dev']
  });

export default i18n;

import axios from 'axios';

const API = process.env.REACT_APP_API_BASE_URL;

export const login = async (data) => {
  const resp = await axios.post(`${API}/account/login`, data);

  return resp.data;
};

export const passwordRecover = async (data) => {
  const resp = await axios.post(`${API}/account/password/recover`, data);

  return resp.data;
};

export const passwordRecoverConfirm = async (data) => {
  const resp = await axios.post(`${API}/account/password/recover/confirm`, data);

  return resp.data;
};

export const getUser = async () => {
  const resp = await axios.get(`${API}/account/user`);

  return resp.data;
};

export const renewTokens = async (refreshToken) => {
  const resp = await axios.post(`${API}/account/token/renew`, {
    refreshToken
  });

  return resp.data;
};

export const deleteAccount = async (accountId) => {
  const resp = await axios.delete(`${API}/account/user/${accountId}`);

  return resp.data;
};
